
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatableLeft.vue";
import SearchBar from "@/components/search-bars/SearchBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AssessorListDrawer from "@/layout/header/partials/assessor/AssessorListEditDrawer2.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "new-entity-course",
  components: {
    Form,
    Datatable,
    SearchBar,
    AssessorListDrawer,
    Swal,
  },
  data() {
    return {
      api_url: "",
      load: true,
      loading: false,
      batch: {
        tranche_id: "",
        entity_info_id: "",
        institute_info_id: "",
        active_status: 0,
      },
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      actionApprove: false,
      actionEdit: false,
      actionReject: false,
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      trainerList: [],
      active_status: 0,
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },

        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Assessor Name",
          key: "assessor_name",
          sortable: true,
        },
        {
          name: "Gender",
          key: "gender",
          sortable: true,
        },
        {
          name: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
      ],
      componentKey: 0,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getEntityTypes();
    await this.getEntityInfos();
    await this.assessorInfo();
    await this.actioncheck();
    this.emitter.on("assessor-updated", async () => {
      this.assessorInfo();
    });
    console.log(this.api_url);
  },
  methods: {
    Reject(id) {
      let user_id=VueCookieNext.getCookie('_seip_user');
      let data = {
        active_status: 1,
        responsible_user_id:user_id.id

      };
      Swal.fire({
        title: "Are you sure you want to reject it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Rejected!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(this.VUE_APP_ASSESSOR_REJECT_API + `${id}`, data)
            .then((response) => {
              this.emitter.emit("assessor-updated", true);
              Swal.fire("Rejected!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Approve(id) {
      let user_id = VueCookieNext.getCookie("_seip_user");

      let data = {
        active_status: 1,
        responsible_user_id: user_id.id,
      };
      Swal.fire({
        title: "Are you sure you want to approve it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approved!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update(this.VUE_APP_ASSESSOR_APPROVE_API + `${id}`, data)
            .then((response) => {
              this.emitter.emit("assessor-updated", true);
              Swal.fire("Approved!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    edit(data) {
      //console.log(data);
      this.emitter.emit("edit-assessor-modal-data", data);
    },
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem("menu") || "{}");
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == "Assessor") {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === "Approve") {
                this.actionApprove = true;
              }
              if (actions[j].action_name === "Edit") {
                this.actionEdit = true;
              }
              if (actions[j].action_name === "Reject") {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    async courseNotice() {
      if (Object.keys(this.trainerList).length === 0) {
        console.log("No Trainer List");
        this.load = false;
        this.assessorInfo().then(() => {
          this.load = false;
          this.showCourseNotice = true;
        });
      } else {
        this.showCourseNotice = true;
      }
    },
    async assessorInfo() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }

      await ApiService.get(this.VUE_APP_PENDING_ASSESSOR_LIST_API)
        .then((response) => {
          this.load = false;
          this.trainerList = response.data.data;

          this.showCourseNotice = true;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("entity/trainer/delete?trainer_id=" + `${id}`)
            .then((response) => {
              this.emitter.emit("assessor-updated", true);
              console.log(this.trainerList);
              this.assessorInfo();
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
